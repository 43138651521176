import { action, computed } from "mobx";
import http from "@app/lib/http";
import BaseStore from "./base";
import SessionStore from "./session";
import ApplicationSettings from "@app/state/model/application/settings";
import notify from "@app/components/notify/index";

export class Application extends BaseStore {
    observable() {
        return {
            settings: null,
            loading: false,
        };
    }

    @computed get enableAI() {
        return this.settings.ai?.enabled && SessionStore.can("copilot");
    }

    @action
    async load() {
        if (this.settings) {
            return;
        }

        try {
            const { data } = await http.get(`/application/settings`);
            this.settings = new ApplicationSettings(data);
        } catch (error) {
            notify.error(error.response?.data?.error);
        }
    }

    @action
    async loadSSOSettings() {
        try {
            const localStorageSettings = this.loadSSOSettingsFromLocalStorage();

            if (localStorageSettings) {
                return;
            }

            this.loading = true;
            const { data } = await http.get(`/application/settings/auth`);
            this.loading = false;

            this.settings = new ApplicationSettings(data);

            this.saveSSOSettingsToLocalStorage();
        } catch (error) {
            notify.error(error.response?.data?.error);
            this.loading = false;
        }
    }

    @action
    setSSOSettings({ enabled }) {
        const ssoSettings = new ApplicationSettings({ sso: { enabled: enabled } });
        this.settings = ssoSettings;
        this.saveSSOSettingsToLocalStorage();
    }

    saveSSOSettingsToLocalStorage() {
        localStorage.setItem("ssoSettings", JSON.stringify(this.settings.sso));
    }

    loadSSOSettingsFromLocalStorage() {
        const ssoSettings = localStorage.getItem("ssoSettings");
        if (ssoSettings) {
            this.settings = new ApplicationSettings({ sso: JSON.parse(ssoSettings) });
            return this.settings;
        }
    }

    clearSSOSettingsFromLocalStorage() {
        localStorage.removeItem("ssoSettings");
    }
}

export default new Application();
